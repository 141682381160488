<template>
  <div class="filter-tree-wrapper">
    <div v-for="(item, index) in treeData" :key="index" class="filter-tree">
      <FilterTreeViewCategory :item="item" />
    </div>
    <div class="new-category-wrapper">
      <input class="new-category" type="text" placeholder="+ Neue Überschrift (z. B. Kollektion)" v-model="newCategory" @keyup.enter="addNewCategory">
    </div>
  </div>
  <div class="save-wrapper">
    <button v-if="product!==null" @click="saveTagsForProduct" class="km__button--standard blue">{{$t("konf_Save")}}</button>
  </div>
</template>

<script lang="ts">
import {useShopItems} from "~/store/shopItemsStore";
import {useShopInfos} from "~/store/shopInfosStore";
import FilterTreeViewCategory from "~/components/FilterTreeViewCategory.vue";

export default {
  name: "FilterTreeView",
  components: {FilterTreeViewCategory},
  props: {
    product: Object
  },
  setup(props) {
    const shopItems= useShopItems();
    const shopInfos = useShopInfos();
    const newCategory = ref('');

    function isTagAddedToProduct(category, tag){
      if(props.product === null){
        return false;
      }
      if(props.product.tags===undefined){
        return false;
      }
      if(props.product.tags.filter(obj => obj.category === category).length<1){
        return false;
      }
      return props.product.tags.filter(obj => obj.category === category)[0].tags.filter(item => item.name===tag).length > 0;
    }

    const treeData = computed(()=> shopItems.getFilterTags ?
        shopItems.getFilterTags.map(obj => ({
          category:obj.category,
          id: obj.id,
          tags: obj.tags.map(tag => {return {
            name: tag.tag,
            id: tag.id,
            checked: isTagAddedToProduct(obj.category, tag.tag)
          }})
        })) : []
    )

    function saveTagsForProduct(){
      const selectedFilters = treeData.value.map(category => ({
            category: category.category,
            tags: category.tags.filter(tag => tag.checked)
          })).filter(category => category.tags.length > 0);
      shopItems.saveTagsForSaveID({shopname: shopInfos.shopname, saveID: props.product.saveID, filters: selectedFilters});
    }

    function addNewCategory(){
      if (newCategory.value.trim()) {
        shopItems.addCategory({shopname: shopInfos.shopname, category:newCategory.value});
        newCategory.value = '';
      }
    }

    return{
      treeData,
      saveTagsForProduct,
      newCategory,
      addNewCategory
    }
  }
}
</script>

<style lang="scss" scoped>

.new-category-wrapper {
  background-color: #EEEEEE;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .new-category {
    font-size: 14px;
    height: 24px;
    margin-right: 0px;
    margin-left: 12px;
    padding-left: 10px;
    border: 1px solid #C0BEBF;
    width: 330px;
  }
}

.save-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .blue {
    width: 180px;
  }
}

.km__button--standard{
  margin-top: 0;
  margin-bottom: 20px;
}

</style>