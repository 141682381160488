import {LanguageLoaderVue} from "@km/js/LanguageLoaderVue";
import {useShopInfos} from "~/store/shopInfosStore";
import {useShopItems} from "~/store/shopItemsStore";
import {useShopSeiten} from "~/store/shopSeitenStore";
import {useFooterStore} from "~/store/FooterStore";
import {getURLPrefixOfflineVsOnline} from "@k/global/globalUtils";

export const loadInitialItemsAndPages = async (shopname:string, language: string, land:string) => {

    const shopitemsResponse = await useFetch(`/shop_php/getAllShopItems.php?shopname=${shopname}&land=${land}&sprache=${language}`);
    const shopItemStore = useShopItems();
    const parsedShopItemResponse = JSON.parse(shopitemsResponse.data.value);
    shopItemStore.setAllShopItems(parsedShopItemResponse.itemsInShop);
    shopItemStore.setFormerShopItems(parsedShopItemResponse.formerShopItems);
    const shopseitenResponse = await useFetch(`/shop_php/getShopSeiten.php?shopname=${shopname}`);
    const shopSeitenStore = useShopSeiten();
    shopSeitenStore.setShopSeiten(JSON.parse(shopseitenResponse.data.value));
    const response = await useFetch(`/shop_php/getSprachen.php`);
    const shopInfoStore = useShopInfos();
    shopInfoStore.setPossibleSprachen(response.data.value);
    const owayoURL = await useFetch(getURLPrefixOfflineVsOnline_nuxt()+"/newhp/php/getLinkShopToOwayo.php?land="+land+"&language="+language+"&shop="+shopname);
    shopInfoStore.SET_OWAYO_URL(owayoURL as {data:{value:string}});
    const footerstore = useFooterStore();
    footerstore.SET_SHOP_SPRACHE(language);
    await footerstore.loadImpressum();
}

export const loadInitialShopInfo = async () =>{
    const route = useRoute();
    const url = useRequestURL();
    const land = LanguageLoaderVue.getLandFromDomain(url.hostname);
    const shopInfoStore = useShopInfos();
    shopInfoStore.SET_SHOP_LAND(land);
    shopInfoStore.SET_SHOPDIRECTORY_LOCALIZED(route.params.shopdirectoryLocalized as string);
    shopInfoStore.SET_PRELIMINARY_SHOPNAME(route.params.shopname as string);
    const shopinfoResponse = await useFetch(`/shop_php/getShopInfo.php?shopname=${route.params.shopname}`, {credentials: 'include'});
    shopInfoStore.setShopInfo(shopinfoResponse.data.value);
}

export function getURLPrefixOfflineVsOnline_nuxt() {
    if(import.meta.env.PROD) {
        return "";
    }
    return "/owayo"
}
