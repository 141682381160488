import {integer} from "vscode-languageserver-types";

export enum PopupNames {
    "editProfil" = "editProfil",
    "editTheme" = "editTheme",
    "editLogo" = "editLogo",
    "editImpressum" = "editImpressum",
    "editFilter" = "editFilter"
}
import {ref} from "vue";

export class PopupStatus {

    private static state = ref({
        editProfil: false,
        editTheme: false,
        editLogo:false,
        editImpressum: false,
        editFilter: false,
        product: Object
    })


    public static editProfilISOpen = () => PopupStatus.state.value.editProfil;
    public static editThemeIsOpen = () => PopupStatus.state.value.editTheme;
    public static editLogoIsOpen = () => PopupStatus.state.value.editLogo;
    public static editImpressumIsOpen = () => PopupStatus.state.value.editImpressum;
    public static editFilterIsOpen = () => PopupStatus.state.value.editFilter;
    public static getProductForFilter = () => PopupStatus.state.value.product;

    public static TOGGLE_POPUP = (payload: PopupNames) => {
        PopupStatus.state.value[payload] = !PopupStatus.state.value[payload];
        [PopupNames.editProfil, PopupNames.editTheme, PopupNames.editLogo, PopupNames.editImpressum, PopupNames.editFilter]
            .filter(n => n !== payload).forEach(n => PopupStatus.state.value[n] = false);
    };

    public static CLOSE_NAVI = () => {
        [PopupNames.editProfil, PopupNames.editTheme, PopupNames.editLogo, PopupNames.editImpressum, PopupNames.editFilter].forEach(n => PopupStatus.state.value[n] = false);
    }

    public static setProduct = (product: Object) => {
        PopupStatus.state.value.product = product;
    }
}


