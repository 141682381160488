import {defineStore} from "pinia";
//@ts-ignore
import {useStore} from "vuex";
import type {Ref} from "vue";
import {useShopItems} from "~/store/shopItemsStore";
import axios from "axios";
import {noop} from "@antfu/utils";

export type ImpressumData = {
    firma: string;
    ansprechpartner: string;
    strasse: string;
    plz: string;
    ort: string;
    iso3166: string;
    email: string;
    telefon: string;
    rot13Email: string;
}


export type ShopInfo = {
    shopLogoPath: string,
    shopName: string,
    theme: string,
    themeBgImage: string,
    shopOwner: string,
    standardSprache: string,
    standardLand: string,
    standardWaehrung: string,
    impressum: ImpressumData,
    anzeigeName: string,
    active: boolean
}

export const useShopInfos = defineStore("shopinfos", () => {


    const shopinfo = ref({
        shopLogoPath: "",
        shopName: "",
        theme: "",
        themeBgImage: "",
        shopOwner: "",
        standardSprache: "",
        standardLand: "",
        standardWaehrung: "",
        impressum: {
            firma: "",
            ansprechpartner: "",
            strasse: "",
            plz: "",
            ort: "",
            iso3166: "",
            telefon: "",
            rot13Email:""
        },
        anzeigeName: "",
        active: true
    } as ShopInfo);
    const isBusy = ref(false);
    const ISO3166 = ref("DE");
    const sprache = ref("DE");
    const possibleSprachen = ref([]) as Ref<string[]>;
    const shopdirectoryLocalized = ref("shop");
    const owayoURL = ref("");

    const shopItemsStore = useShopItems();
    const vuexStore = useStore();
    const actions = {
        isLoading: computed(() => isBusy),
        getShopSprache: computed(() => shopinfo.value.standardSprache),
        getShopLand: computed(() => shopinfo.value.standardLand),
        shopInfo: computed(() => shopinfo.value),
        shoplogopath: computed(() => shopinfo.value.shopLogoPath || ""),
        shopname: computed(() => shopinfo.value.shopName),
        theme: computed(() => shopinfo.value.theme),
        getThemeBgImage: computed(() => shopinfo.value.themeBgImage),
        getShopImpressum: computed(() => shopinfo.value.impressum),
        getWarenkorbCallParameter: computed(() => "land=" + ISO3166.value + "&lang=" + sprache.value + "&shop=" + shopinfo.value.shopName),
        getReturnURL: computed(() => window.location),
        isShopOwner: computed(() => shopinfo.value.shopOwner === vuexStore.getters.getUsername && shopinfo.value.shopOwner !== ""),
        getSprachen: computed(() => possibleSprachen.value),
        getShopdirectoryLocalized: computed(() => shopdirectoryLocalized.value),
        getStandardWaehrung: computed(() => shopinfo.value.standardWaehrung),
        getDomainLand: computed(() => ISO3166.value),
        getDomainSprache: computed(() => sprache.value),
        getShopAnzeigename: computed(() => shopinfo.value.anzeigeName),
        isShopActive: computed(() => shopinfo.value.active),
        getOwayoURL: computed(() => owayoURL.value),
        getInfos: (shopname: string) => {
            return new Promise((resolve, reject) => {
                $fetch(`/shop_php/getShopInfo.php?shopname=${shopname}`, {credentials: 'include'}).then(response => {
                    let parsed = JSON.parse(response);
                    actions.setShopInfo(parsed);
                    resolve(parsed)
                    shopItemsStore.getAllShopItems(shopname);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setShopLogoPath: (pfad: string) => shopinfo.value.shopLogoPath = pfad,
        setShopInfo: (shopData: any) => {
            if(shopData === "shop not found") {
                throw createError({
                    statusCode: 404,
                    message: 'shop not found',
                    fatal: true
                })
            }
            if (typeof (shopData) === "string") {
                shopData = JSON.parse(shopData);
            }
            shopinfo.value.shopLogoPath = shopData.shopLogoPath;
            shopinfo.value.shopName = shopData.shopName;
            shopinfo.value.theme = shopData.theme;
            shopinfo.value.themeBgImage = shopData.themeBgImage;
            shopinfo.value.shopOwner = shopData.shopOwner;

            shopinfo.value.impressum.rot13Email = shopData.rot13Email;
            shopinfo.value.impressum.telefon = shopData.telefon;
            shopinfo.value.impressum.firma = shopData.firma;
            shopinfo.value.impressum.ansprechpartner = shopData.ansprechpartner;
            shopinfo.value.impressum.strasse = shopData.strasse;
            shopinfo.value.impressum.plz = shopData.plz;
            shopinfo.value.impressum.ort = shopData.ort;
            shopinfo.value.standardSprache = shopData.sprache;
            shopinfo.value.standardWaehrung = shopData.standardWaehrung;
            shopinfo.value.anzeigeName = shopData.anzeigeName;
            shopinfo.value.impressum.iso3166 = shopData.iso3166;
            shopinfo.value.standardLand = shopData.standardLand;
            shopinfo.value.active = shopData.active === "1";
        },
        setBusy: () => isBusy.value = true,
        clearBusy: () => isBusy.value = false,
        SET_SHOP_SPRACHE: (spracheToSet: string) => sprache.value = spracheToSet,
        SET_SHOP_LAND: (iso3166: string) => ISO3166.value = iso3166,
        SET_SHOP_THEME: (data: { theme: string, bgImage: string }) => {
            shopinfo.value.theme = data.theme;
            shopinfo.value.themeBgImage = data.bgImage;
        },
        SET_OWAYO_URL(url:{data:{value:string}}): void {
            owayoURL.value = url.data.value;
        },
        SET_SHOPDIRECTORY_LOCALIZED: (shopdirectoryLocalizedToSet: string) => shopdirectoryLocalized.value = shopdirectoryLocalizedToSet,
        SET_PRELIMINARY_SHOPNAME: (shopname: string) => shopinfo.value.shopName = shopname,
        SET_USERSHOP_ANZEIGENAME: (shopAnzeigename: string) => shopinfo.value.anzeigeName = shopAnzeigename,
        setPossibleSprachen: (data:string[]) => possibleSprachen.value = data,
        saveNewImpressumAdresse:(adresse:{id:string}) => {
            let payload = '{"shopName":"'+actions.shopname.value+'",' +
                '"addressID":"'+adresse.id+'"}';
            axios.post("/konfigurator_php/sessiondata/changeImpressumAdresse.php", payload
            ).then(response=>{
                actions.getInfos(actions.shopname.value).then(noop);
            });
        }
    }

    return actions;

})
