<script setup lang="ts">
const error = useError();
const link = window?.location.origin;
</script>

<template>

    <div class="prose">
      <template v-if="error.statusCode === 404">
        <h1>404 - Page not found</h1>
        <p>Sorry, that page doesn't exist.</p>
      </template>
      <template v-else>
        <h1>Sorry!</h1>
        <p>
          <strong>{{ error.message }}</strong>
        </p>
        <p>It looks like something broke.</p>
        <p>Sorry about that.</p>
      </template>
      <p>
        <ClientOnly>
        <a :href="link">Home
        </a>
        </ClientOnly>
      </p>
    </div>

</template>

<style scoped lang="scss">
h1 {
  text-align: center;
  font-size: 4rem;
}
p {
  text-align: center;
}
</style>
