import {ContentWithLevel} from "@k/js/delme_globals";
import {useFooterStore} from "~/store/FooterStore";
import {useShopInfos} from "~/store/shopInfosStore";

export const loadWebsitelinks = async (language:string) => {
    const contentArray = [
        new ContentWithLevel("agbs", 1),
        new ContentWithLevel("faq_neu", 1),
        new ContentWithLevel("lieferzeit_neu", 1),
        new ContentWithLevel("datenschutzerklaerung", 1),
    ];
    const shopInfo = useShopInfos();

    if(language==='us'){
        language = 'en';
    }

    const body = {
        land: shopInfo.getDomainLand,
        sprache: language,
        sportWebInt: "",
        targetContents: contentArray,
        product: ""
    };
    const linkerstellerResult = await useFetch("/konfigurator_php/actionmodul/linkErsteller.php", {
        headers: {
            'Content-Type': 'application/json',
        },
        body: body,
        method: "POST"
    })
    const footerStore = useFooterStore();
    footerStore.SET_WEBSITE_LINKS(JSON.parse(linkerstellerResult.data.value));
}
