import { createStore } from 'vuex';

import user from "@km/store/UserStore";
import warenkorb from "@km/store/WarenkorbZusammenfassung";
import TabOpenStatus from "@km/store/frontendstate/TabOpenStatusStore";

export default createStore({
  modules: {
    user,
    warenkorb,
    TabOpenStatus,
  }
})
