import {useShopInfos} from "~/store/shopInfosStore";
import {INTERFRAMEMESSAGES} from "@k/global/InterframeMessages";
import {EventListener} from "@k/global/eventVerteiler";
import {useShopStore} from "@ur/components/store/UserShopStore";
import {initShopMessageEventListeners} from "~/components/shopMessageEventListener";
import type {Ref} from "vue";


export const initEventListeners = (eventListener:EventListener, store:{getters:any, dispatch:any, commit:any}, loggedIn:Ref<boolean>, i18n: { t:(s:string)=>string }) => {
    const shopInfoStore = useShopInfos();
    eventListener
        .addEventListener("userLoggedInEvent", () => {
            loggedIn.value = true;
        })
        .addEventListener(INTERFRAMEMESSAGES.USERMANAGEMENT_LOGIN_SUCCESS, (data:{loginResponse:string, shopname:string}) => {
            store.dispatch("setLoggedInUser", data.loginResponse);
            store.dispatch('getAllPossibleProducts', shopInfoStore.shopname);
            loggedIn.value = true;
        })
        .addEventListener(INTERFRAMEMESSAGES.LOGOUT_SUCCEEDED, (data:string) => {
            store.dispatch("setLoggedOutUser", data);
            loggedIn.value = false;
        })
        .addEventListener([
            INTERFRAMEMESSAGES.USERMANAGEMENT_LOGIN_SUCCESS,
            INTERFRAMEMESSAGES.USERMANAGEMENT_LOGIN_FAIL,
            INTERFRAMEMESSAGES.LOGOUT_FAILED,
            INTERFRAMEMESSAGES.LOGOUT_SUCCEEDED,
            INTERFRAMEMESSAGES.USERMANAGEMENT_REGISTRATION_SUCCESS,
            INTERFRAMEMESSAGES.WARENKORB_CHANGED,
            INTERFRAMEMESSAGES.REMOVE_ENTWURF_FROM_WARENKORB,
            INTERFRAMEMESSAGES.UPDATE_WARENKORBSYMBOL,
            "userLoggedInEvent"
        ], () => {
            store.dispatch("loadWarenkorbUebersicht", {
                land: shopInfoStore.getShopLand,
                lang: shopInfoStore.getShopSprache,
                shop: shopInfoStore.shopname
            });
            store.dispatch("loadAdressen");
        })
        .addEventListener(INTERFRAMEMESSAGES.SWITCH_ADDRESSE, (data:{adresse: { id:string }}) => {
            const shopInfos = useShopInfos();
            shopInfos.saveNewImpressumAdresse(data.adresse);
        })
        .addEventListener("userLoggedOutEvent", () => {
            store.commit("SET_ENTWURF_LISTE", []);
            store.commit("SETLOGOPOOL", []);
            store.commit("SET_ORDERS", []);
            loggedIn.value = false;
        })
        .addEventListener(INTERFRAMEMESSAGES.RESIZE_IFRAME, (data:{iframeID?:string, height:number, width:number}) => {
            resizeIframe(data.iframeID || "iFrameUserManagement", data.height || -1, data.width || -1);
        })
        .addEventListener(INTERFRAMEMESSAGES.USERMANAGEMENT_CLOSE, () => {
            resizeIframe("iFrameUserManagement", 1, "1");
        })
        // .addEventListener(INTERFRAMEMESSAGES.ROUTE_CHANGE, data => {
        //   IframeViewController.routeChange(data);
        // })
        .addEventListener(INTERFRAMEMESSAGES.OPEN_BESTELLUNGEN, () => {
            let returnURL = store.getters.getReturnURL;
            let urlLink = location.protocol + "//" + location.hostname + "/shoppingcart?view=orders&land=" + store.getters["getLand"].toLowerCase() + "&lang=" + store.getters["getLanguage"].toLowerCase();
            if (store.getters.shopname !== "") {
                urlLink = urlLink + "&shop=" + store.getters.shopname;
            }
            document.cookie = "Shoppingcart_ReturnURL=" + returnURL + ";path=/";
            window.location.href = urlLink;
        });

    initShopMessageEventListeners(i18n.t);

    watch(() => store.getters.isLoggedIn, () => {
        store.dispatch("loadWarenkorbUebersicht", {
            land: store.getters.getShopLand,
            lang: shopInfoStore.getShopSprache,
            shop: shopInfoStore.shopname
        });
    })

};

function resizeIframe(iframeID:string, communicatedHeight = -1, communicatedWidth = "" as number | string) {
    const iframe: HTMLIFrameElement = document.getElementById(iframeID) as HTMLIFrameElement;
    if (iframe === null) {
        return;
    }

    const height = communicatedHeight > 0 ? communicatedHeight : 500;

    // @ts-ignore
    iframe.style["min-height"] = height+"px";

    if (typeof (communicatedWidth) === "string") {
        iframe.width = "100px";
        if (communicatedWidth.indexOf("%") > -1) {
            iframe.width = communicatedWidth;
            return;
        }
        return;
    }

    if (communicatedWidth > -1) {
        iframe.width = "100px";
        iframe.width = (parseInt((communicatedWidth as number).toString(), 10)) + "px";
    }
}
