import type {StringDictionary} from "@k/js/delme_globals";

export const LANDSPRACHETODOMAINMAP = {

    at:{
        de:"www.owayo.at"
    },
    au:{
        en:"www.owayo.com.au"
    },
    be:{
        nl:"www.owayo.be",
        fr:"fr.owayo.be"
    },
    ca:{
        en:"www.owayo.ca",
        fr:"fr.owayo.ca"
    },
    ch:{
        de: "www.owayo.ch",
        fr: "fr.owayo.ch",
        it: "it.owayo.ch"
    },
    de:{
        de:"www.owayo.de",
    },
    es:{
        es:"www.owayo.es"
    },
    fr:{
        fr:"www.owayo.fr",
    },
    gb:{
        en:"www.owayo.co.uk"
    },
    en: {
        en:"www.owayo.co.uk"
    },
    ie:{
        en:"www.owayo.ie"
    },
    it:{
        it:"www.owayo.it"
    },
    jp:{
        jp:"www.owayo.jp"
    },
    kr:{
        kr:"www.owayo.kr"
    },
    nl:{
        nl:"www.owayo.nl"
    },
    nz:{
        en:"www.owayo.co.nz"
    },
    pl:{
        en:"www.owayo.pl"
    },
    ru:{
        en:"www.owayo.ru"
    },
    us:{
        en:"www.owayo.com",
        es:"us-es.owayo.com"
    },
    wo:{
        fr:"fr.owayo.com",
        es:"es.owayo.com",
        en:"en.owayo.com",
    }
} as StringDictionary<StringDictionary<string>>


