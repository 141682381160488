
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es",
  "fr",
  "it",
  "jp",
  "kr",
  "nl"
]

export const localeLoaders = {
  "de": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts?hash=f5e0bcfe&locale=de" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_de_ts" */), cache: false }],
  "en": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts?hash=f603b9da&locale=en" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_en_ts" */), cache: false }],
  "es": [{ key: "../lang/es.ts", load: () => import("../lang/es.ts?hash=a445c55b&locale=es" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_es_ts" */), cache: false }],
  "fr": [{ key: "../lang/fr.ts", load: () => import("../lang/fr.ts?hash=a5f1687a&locale=fr" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_fr_ts" */), cache: false }],
  "it": [{ key: "../lang/it.ts", load: () => import("../lang/it.ts?hash=c459ee92&locale=it" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_it_ts" */), cache: false }],
  "jp": [{ key: "../lang/jp.ts", load: () => import("../lang/jp.ts?hash=a616c350&locale=jp" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_jp_ts" */), cache: false }],
  "kr": [{ key: "../lang/kr.ts", load: () => import("../lang/kr.ts?hash=767e4e3a&locale=kr" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_kr_ts" */), cache: false }],
  "nl": [{ key: "../lang/nl.ts", load: () => import("../lang/nl.ts?hash=91055b57&locale=nl" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_lang_nl_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "files": [
        "lang/de.ts"
      ]
    },
    {
      "code": "en",
      "files": [
        "lang/en.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "lang/es.ts"
      ]
    },
    {
      "code": "fr",
      "files": [
        "lang/fr.ts"
      ]
    },
    {
      "code": "it",
      "files": [
        "lang/it.ts"
      ]
    },
    {
      "code": "jp",
      "files": [
        "lang/jp.ts"
      ]
    },
    {
      "code": "kr",
      "files": [
        "lang/kr.ts"
      ]
    },
    {
      "code": "nl",
      "files": [
        "lang/nl.ts"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "files": [
      {
        "path": "lang/de.ts"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "lang/en.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "lang/es.ts"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "lang/fr.ts"
      }
    ]
  },
  {
    "code": "it",
    "files": [
      {
        "path": "lang/it.ts"
      }
    ]
  },
  {
    "code": "jp",
    "files": [
      {
        "path": "lang/jp.ts"
      }
    ]
  },
  {
    "code": "kr",
    "files": [
      {
        "path": "lang/kr.ts"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "lang/nl.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
