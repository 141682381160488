import {defineStore} from "pinia";
import {useShopInfos} from "~/store/shopInfosStore";

import type {Ref} from "vue";

export type ShopSeite = {
    Seite: string
    BeschreibungContent: string
    MetaTitle: string
    MetaDescription: string
};

export const useShopSeiten = defineStore("shopseiten", () => {

    const shopInfoStore = useShopInfos();
    const seiten = ref({shopseiten: {Produktseite:null}}) as Ref<{shopseiten:{Produktseite:ShopSeite|null}}>;
    const actions = {
        getProduktSeite: computed(() => {
            return seiten.value.shopseiten["Produktseite"]
        }),
        getShopSeiten: (shopname: string) => {
            const response = $fetch(
                `/shop_php/getShopSeiten.php?shopname=${shopname}`
            ).then(response=>actions.setShopSeiten(JSON.parse(response)));
        },
        saveProduktseitenBeschreibung: async (data:{shopname:string, anzeigename:string, beschreibung:string, metaTitle:string, metaDescription:string}) => {
            const transferObj: any = {};
            transferObj.shopname = data.shopname;
            transferObj.anzeigename = data.anzeigename;
            transferObj.beschreibung = data.beschreibung;
            transferObj.metaTitle = data.metaTitle;
            transferObj.metaDescription = data.metaDescription;

            $fetch('/shop_php/saveProduktseitenBeschreibung.php', {body: transferObj, method: "POST"})
                .then(function (response) {
                    if (JSON.parse(response) === "success") {
                        actions.getShopSeiten(data.shopname);
                        shopInfoStore.getInfos(data.shopname)
                    }
                })
        },
        setShopSeiten: (shopseiten:{Produktseite:ShopSeite}) => {
            seiten.value.shopseiten = shopseiten;

        },
        setProduktseite(produktseite:ShopSeite) {
            seiten.value.shopseiten.Produktseite = produktseite;
        }
    }

    return actions;

});
