import {EventVerteiler} from "@k/global/eventVerteiler";
import {UserStoreWrapper} from "@km/js/UserStoreWrapper";
import {SessionController} from "@k/global/SessionController";
import ErrorHandlerAlert from "@km/js/ErrorHandlerAlert";
import type {Ref} from "vue";

export const initSession = (store:any, i18n:{t:(s:string)=>string}, loggedIn:Ref<boolean>) => {
    const callback = function (data: { username:string, logged_in:boolean }) {
        const username = data.username;
        loggedIn.value = data.logged_in;
        if (loggedIn.value) {
            EventVerteiler.broadcast("userLoggedInEvent", {username: username});
        }
        const user = new UserStoreWrapper(store);
        user.setUserFromInitialSession(username, loggedIn.value);
        EventVerteiler.broadcast("initialSessionarrivedEvent");
    };

    SessionController.getInitialSession(callback, new ErrorHandlerAlert(), id => i18n.t(id));
}
