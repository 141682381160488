import { default as _91id_93gXn4SIMMAAMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/[id].vue?macro=true";
import { default as draftscvfAYp2Y3SMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/drafts.vue?macro=true";
import { default as impressum3I5f9UvnCCMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/impressum.vue?macro=true";
import { default as indexoV8V8gwHcwMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/index.vue?macro=true";
import { default as summaryKr2aWONVJoMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/summary.vue?macro=true";
import { default as indexhJOdWhst5WMeta } from "E:/www/shop_nuxt/pages/index.vue?macro=true";
export default [
  {
    name: "shopdirectoryLocalized-shopname-id",
    path: "/:shopdirectoryLocalized()/:shopname()/:id()",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/[id].vue").then(m => m.default || m)
  },
  {
    name: "shopdirectoryLocalized-shopname-drafts",
    path: "/:shopdirectoryLocalized()/:shopname()/drafts",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/drafts.vue").then(m => m.default || m)
  },
  {
    name: "shopdirectoryLocalized-shopname-impressum",
    path: "/:shopdirectoryLocalized()/:shopname()/impressum",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/impressum.vue").then(m => m.default || m)
  },
  {
    name: "shopdirectoryLocalized-shopname",
    path: "/:shopdirectoryLocalized()/:shopname()",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/index.vue").then(m => m.default || m)
  },
  {
    name: "shopdirectoryLocalized-shopname-summary",
    path: "/:shopdirectoryLocalized()/:shopname()/summary",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/summary.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/www/shop_nuxt/pages/index.vue").then(m => m.default || m)
  }
]