import {UserManagementConnectorRoutes} from "@k/global/UserManagementConnectorRoutes";
import {type EnumDictionary} from "@k/js/delme_globals";
//@ts-ignore
import {NavigationOpenRequestTypeFromShoppingcartMenu} from "owayo_menu";

export class Navigation_opencomponentHandler_SHOP {
    private static naviShoppingcart_openRequestActionMapUserManagementRoutes = {
        [NavigationOpenRequestTypeFromShoppingcartMenu.login]: () => new UserManagementConnectorRoutes().login(),
        [NavigationOpenRequestTypeFromShoppingcartMenu.logout]: () => new UserManagementConnectorRoutes().logout(),
        [NavigationOpenRequestTypeFromShoppingcartMenu.openChooseAdresse]: (adressType="impressum") => {
            new UserManagementConnectorRoutes().openChooseAdresse("iFrameUserManagement", adressType)
        },
        [NavigationOpenRequestTypeFromShoppingcartMenu.orders]: () => console.warn("orders öffnen noch nicht implementiert in diesem Connector"),
        [NavigationOpenRequestTypeFromShoppingcartMenu.account]: () => new UserManagementConnectorRoutes().openMyAccount(),
        [NavigationOpenRequestTypeFromShoppingcartMenu.shoppingcart]: () => {

            let returnURL = Navigation_opencomponentHandler_SHOP.store.getReturnURL;
            document.cookie = "Shoppingcart_ReturnURL=" + returnURL + ";path=/";
            window.location.href = location.protocol + "//" + location.hostname + "/shoppingcart" + Navigation_opencomponentHandler_SHOP.store.getWarenkorbCallParameter;
        },
    } as EnumDictionary<NavigationOpenRequestTypeFromShoppingcartMenu, () => void>

    private static store: any;


    public static open_component(component: NavigationOpenRequestTypeFromShoppingcartMenu) {
        Navigation_opencomponentHandler_SHOP.naviShoppingcart_openRequestActionMapUserManagementRoutes[component]()
    }
    public static setShopInfoStore(store:any) {
        Navigation_opencomponentHandler_SHOP.store = store;
    }
}
